import { MdEmail, MdFacebook } from 'react-icons/md';
import { IoLogoWhatsapp } from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../assets/styles/footer.scss';
import logo from '../assets/images/logo_blanco.png';

function Footer() {
  const [t] = useTranslation('translation');

  return (
    <footer className='container-fluid '>
      <div className='footer'>
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 pt-4'>
          <h2 className='footer__title'>{t('conocenos')}</h2>
          <ul className='col-12 row p-0'>
            <li className='footer_link'>
              <Link to='/'>{t('inicio')}</Link>
            </li>
            <li className='footer_link'>
              <Link to='/donate'>{t('comoAyudar')}</Link>
            </li>
            <li className='footer_link'>
              <Link to='/about'>{t('conocenos')}</Link>
            </li>
          </ul>
        </div>
        <div className='col-12 col-sm-12 col-md-4 col-lg-4 pt-4'>
          <h2 className='footer__title'>{t('contactanos')}</h2>
          <ul className='col-12 row p-0'>
            <li className='footer_link'>
              <a href='mailto:momoteescucha@gmail.com'>
                <MdEmail className='img-fit' />
                momoteescucha@gmail.com
              </a>
            </li>
            <li className='footer_link'>
              <a
                target='_blank'
                href='https://wa.me/51941445740'
                rel='noreferrer'
              >
                <IoLogoWhatsapp />
                +51 941445740
              </a>
            </li>
          </ul>
        </div>
        <div className='row col-12 col-sm-12 col-md-6 col-lg-4 pt-4 img-center'>
          <Link className='col-12' to='/'>
            <img
              src={logo}
              loading='lazy'
              alt='Imagen no disponible'
              className='col-12 mb-4 img-fluid'
            />
            <p className='color-white text-center footer-slogan'>
              {t('footerSlogan')}
            </p>
          </Link>
          <ul className='col-12 row p-0 justify-content-center'>
            <li className='col col-md-3 footer_rs'>
              <a
                target='_blank'
                href='https://www.facebook.com/momoteescucha'
                rel='noreferrer'
              >
                <MdFacebook />
              </a>
            </li>
            <li className='col col-md-3 footer_rs'>
              <a
                target='_blank'
                href='https://www.instagram.com/momoteescucha/'
                rel='noreferrer'
              >
                <AiFillInstagram />
              </a>
            </li>
            <li className='col col-md-3 footer_rs'>
              <a
                target='_blank'
                href='https://wa.me/51941445740'
                rel='noreferrer'
              >
                <IoLogoWhatsapp />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
