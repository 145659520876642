import ReactDOM from 'react-dom';
import './assets/styles/app.scss';
import { I18nextProvider } from 'react-i18next';
import App from './routes/App';
import i18n from './config/i18n';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById('app'),
);
