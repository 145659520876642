import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import Lottie from 'react-lottie';
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import Donate from '../assets/animations/donate.json';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#d8cce1',
  borderRadius: '5px',
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Donate,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const buttonStyle = {
  position: 'fixed',
  top: '15%',
  zIndex: '4',
  right: '20px',
  textAlign: 'center',
  minWidth: '60px',
  minHeight: '60px',
  backgroundColor: 'rgba(139, 89, 186)',
  color: 'white',
  borderColor: 'rgb(96, 53, 136)',
  fontSize: '1.3rem',
};

export default function DonateModal() {
  const [modalState, setModalState] = useState(false);

  const handleTogle = () => {
    setModalState(!modalState);
  };

  return (
    <div>
      <button
        type='button'
        style={buttonStyle}
        className='rounded-circle'
        onClick={handleTogle}
      >
        Donar
      </button>
      <Modal
        open={modalState}
        onClose={handleTogle}
        aria-labelledby='keep-mounted-modal-title'
        aria-describedby='keep-mounted-modal-description'
      >
        <Box
          sx={style}
          className='col-12 d-flex row justify-content-around donate-modal'
        >
          <div className='col-12 btn donate-modal__button-close'>
            <AiOutlineClose
              onClick={handleTogle}
            />
          </div>
          <Lottie
            options={defaultOptions}
            className='col-12'
            isClickToPauseDisabled={true}
          />
          <Link
            to='/donate'
            className='btn donate-modal__button-primary col-8'
            onClick={handleTogle}
          >
            Donar
          </Link>
        </Box>
      </Modal>
    </div>
  );
}
