import { MdGroups, MdSupportAgent } from 'react-icons/md';
import { FaPrayingHands } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Footer from '../components/footer';
import Header from '../components/header';
import '../assets/styles/home.scss';

import bg01 from '../assets/images/backgrounds/bg_01.svg';
import bg02 from '../assets/images/backgrounds/bg_02.svg';
import bg03 from '../assets/images/backgrounds/bg_03.svg';

import cr01 from '../assets/images/mensaje_01.jpg';
import cr02 from '../assets/images/mensaje_02.jpg';
import cr03 from '../assets/images/mensaje_03.jpg';
import cr04 from '../assets/images/mensaje_04.jpg';
import cr05 from '../assets/images/mensaje_05.jpg';
import cr06 from '../assets/images/mensaje_06.jpg';

import momo01 from '../assets/images/personajes_01.png';
import momo02 from '../assets/images/personajes_06.png';

import pilares from '../assets/images/pilares_01.png';
import Testimonio from '../components/testimonio';
import Aliado from '../components/aliado';
import DonateModal from '../components/donateModal';

function Home() {
  const [t] = useTranslation('translation');

  return (
    <div className='container-fluid bg-white'>
      {/* <!-- Navigation --> */}
      <Header />
      {/* <!-- Carousel --> */}
      <section
        id='carouselIndicators'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='3'
            aria-label='Slide 4'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='4'
            aria-label='Slide 5'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='5'
            aria-label='Slide 6'
          />
        </div>
        <div className='carousel-inner'>
          <div className='carousel-item carousel-item--banner active'>
            <div>
              <img
                src={cr01}
                className='banner__img'
                loading='lazy'
                alt='No disponible'
              />
            </div>
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselMessageUNO')}
                </h4>
                <p className='carousel-message__secondary line-height-carrusel'>
                  {t('carouselMessageDOS')}
                </p>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr02}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselDosMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselDosMessageDOS')}
                </p>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr03}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselTresMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {' '}
                  {t('carouselTresMessageDOS')}
                </p>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr04}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay '>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselCuatroMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselCuatroMessageDOS')}
                </p>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr05}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay '>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselCincoMessageUNO')}
                </h4>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr06}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay '>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselSeisMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselSeisMessageDOS')}
                </p>
                <Link
                  to='/donate'
                  className='btn btn-primary btn-lg carousel-message__btn'
                >
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='prev'
        >
          <span className='carousel-control-prev-icon' aria-hidden='true' />
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='next'
        >
          <span className='carousel-control-next-icon' aria-hidden='true' />
          <span className='visually-hidden'>Next</span>
        </button>
      </section>
      {/* <!-- Description --> */}
      <section
        className='container-fluid d-flex justify-content-center pt-6 bg-white'
        id='description'
      >
        <img src={bg01} alt='No disponible' className='momo-bg-01' />
        <div className='main'>
          <img
            src={momo02}
            loading='lazy'
            alt='momo personaje'
            className='momo-img-01'
          />
          <h1 className='title text-center color-primary'>MOMO</h1>
          <p className='main__description'>{t('MomoTex')}</p>

          <Link to='/about' className='btn btn-second btn-lg text-blod'>
            {t('conocenos')}
          </Link>
        </div>
      </section>
      {/* <!-- Pilares --> */}
      <section className='container-fluid d-flex justify-content-center pt-6 bg-white'>
        <img src={bg03} alt='No disponible' className='momo-bg-02 img-revert' />
        <div className='pilar-section'>
          <img
            src={momo01}
            alt='No disponible'
            loading='lazy'
            className='momo-img-01 img-revert'
          />
          <h2 className='title text-center color-primary'>
            {t('Nuestros Pilares')}
          </h2>
          <img
            src={pilares}
            loading='lazy'
            alt='pilares Momo'
            className='momo-img-05 img-center'
          />
        </div>
      </section>
      {/* <!-- Mision y vision --> */}
      <section className='container-fluid row d-flex justify-content-around pt-6 mb-8 bg-white'>
        <div className='card bg-primary-dark col-10 col-md-5 col-lg-5 col-xl-5 mt-6'>
          <div className='card-body'>
            <h5 className='card-title title color-white'>
              {t('tituloProposito')}
            </h5>
            <p className='card-text fs-4 color-white'>{t('proposito')}</p>
          </div>
        </div>

        <div className='card bg-primary-dark col-10 col-md-5 col-lg-5 col-xl-5 mt-6'>
          <div className='card-body'>
            <h5 className='card-title title color-white'>
              {t('tituloVision')}
            </h5>
            <p className='card-text fs-4 color-white'>{t('vision')}</p>
          </div>
        </div>
      </section>
      {/* <!-- Contacto --> */}
      <section className='pt-6 pb-6 col-12 contact-section bg-primary-light'>
        <Link
          to='/about'
          className='col-4 col-sm-3 col-md-4 contact-link text-blod'
        >
          <MdGroups />
          {t('conocenos')}
        </Link>
        <Link
          to='/donate'
          className='col-4 col-sm-3-md-4 contact-link text-blod'
        >
          <FaPrayingHands />
          {t('Apoyanos')}
        </Link>

        <Link
          to='/about'
          className='col-4 col-sm-3 col-md-4 contact-link text-blod'
        >
          <MdSupportAgent />
          {t('contactanos')}
        </Link>
      </section>

      {/* Testimonios */}
      <section className='bg-white pt-6 mb-6'>
        <img src={bg02} alt='No disponible' className='momo-bg-03 pb-5' />
        <Testimonio />
      </section>

      {/* Aliados */}
      <section className='bg-fifth-light'>
        <Aliado />
      </section>

      {/* footer */}
      <div className='bg-primary-dark pt-4'>
        <Footer />
      </div>

      {/* <AdModal />
       */}
      <DonateModal />
    </div>
  );
}

export default Home;
