import '../assets/styles/testimonio.scss';
import { useTranslation } from 'react-i18next';
import test1 from '../assets/images/testimonios/yochang.jpg';
import test2 from '../assets/images/testimonios/ronald_a.jpeg';
import test3 from '../assets/images/testimonios/giselle_morales.jpg';
import test4 from '../assets/images/testimonios/juan_v.jpg';
import momo from '../assets/images/personajes_02.png';

function Testimonio() {
  const [t] = useTranslation('translation');

  return (
    <div className='container-fluid d-flex row justify-content-center bg-transparent'>
      <div className='testimonio-title row justify-content-center'>
        <img
          src={momo}
          alt='No disponible'
          loading='lazy'
          className='momo-img-01'
        />
        <h2 className='title text-center color-primary pb-5'>
          {t('nuestroTestimonio')}
        </h2>
      </div>

      <div
        id='testimonialIndicators'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#testimonialIndicators'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          />
          <button
            type='button'
            data-bs-target='#testimonialIndicators'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          />
          <button
            type='button'
            data-bs-target='#testimonialIndicators'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          />
          <button
            type='button'
            data-bs-target='#testimonialIndicators'
            data-bs-slide-to='3'
            aria-label='Slide 4'
          />
        </div>
        <div className='carousel-inner'>
          <div className='carousel-item active'>
            <div className='testimonio-card'>
              <div className='testimonio-card__title'>
                <img
                  src={test1}
                  className='momo-img-0 rounded-circle'
                  loading='lazy'
                  alt='No disponible'
                />
                <p className='color-white title_02'>Yochang Y.</p>
              </div>
              <p className='testimonio-card__text color-white'>
                {t('YochangTestimonio')}
              </p>
            </div>
          </div>
          <div className='carousel-item'>
            <div className='testimonio-card'>
              <div className='testimonio-card__title'>
                <img
                  src={test2}
                  className='momo-img-0 rounded-circle'
                  loading='lazy'
                  alt='No disponible'
                />
                <p className='color-white'>Ronald A.</p>
              </div>
              <p className='testimonio-card__text'>{t('RonaldTestimonio')}</p>
            </div>
          </div>
          <div className='carousel-item'>
            <div className='testimonio-card'>
              <div className='testimonio-card__title'>
                <img
                  src={test3}
                  className='momo-img-0 rounded-circle'
                  loading='lazy'
                  alt='No disponible'
                />
                <p className='color-white title_02'>Giselle M.</p>
              </div>
              <p className='testimonio-card__text color-white'>
                {t('GiselleTestimonio')}
              </p>
            </div>
          </div>
          <div className='carousel-item'>
            <div className='testimonio-card'>
              <div className='testimonio-card__title'>
                <img
                  src={test4}
                  className='momo-img-0 rounded-circle'
                  loading='lazy'
                  alt='No disponible'
                />
                <p className='color-white title_02'>Juan José V.</p>
              </div>
              <p className='testimonio-card__text color-white'>
                {t('JuanJoseTestimonio')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonio;
