import '../assets/styles/about.scss';
import { useTranslation } from 'react-i18next';
import { AiFillLinkedin } from 'react-icons/ai';
import { Link } from 'react-router-dom';

import Aliado from '../components/aliado';
import Footer from '../components/footer';
import Header from '../components/header';
import momo01 from '../assets/images/personajes_03.png';
import momo02 from '../assets/images/personajes_02.png';
import momo03 from '../assets/images/personajes_06.png';

import value01 from '../assets/images/v_amor.png';
import value02 from '../assets/images/v_servicio.png';
import value03 from '../assets/images/v_libertad.png';
import value04 from '../assets/images/v_igualdad.png';
import value05 from '../assets/images/v_grupo.png';
import value06 from '../assets/images/v_escucha.png';

import cr01 from '../assets/images/banners/about_banner01.jpg';
import cr02 from '../assets/images/banners/about_banner02.jpg';
import cr03 from '../assets/images/banners/about_banner03.jpg';

import collab01 from '../assets/images/collaborators/karla_rejas.png';
import collab02 from '../assets/images/collaborators/edwin.jpg';
import collab03 from '../assets/images/collaborators/avril_olarte.jpg';
import collab05 from '../assets/images/collaborators/giselle_morales.png';
import collab06 from '../assets/images/collaborators/valeria_reyes.jpeg';
import collab07 from '../assets/images/collaborators/esthefany_linares.jpg';
import collab08 from '../assets/images/collaborators/gustavo_jimenez.jpg';
import collab09 from '../assets/images/collaborators/juan_v.jpg';

function About() {
  const [t] = useTranslation('translation');

  return (
    <div className='container-fluid  bg-white'>
      {/* <!-- Navigation --> */}
      <Header />
      {/* <!-- Carousel --> */}
      <div
        id='carouselIndicators'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          />
        </div>
        <div className='carousel-inner'>
          <div className='carousel-item carousel-item--banner active'>
            <div>
              <img
                src={cr02}
                className='banner__img'
                loading='lazy'
                alt='No disponible'
              />
            </div>
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselMessageUNO')}
                </h4>
                <p className='carousel-message__secondary line-height-carrusel'>
                  {t('carouselMessageDOS')}
                </p>
                <Link to='/donate' className='btn btn-primary btn-lg'>
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr03}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselDosMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselDosMessageDOS')}
                </p>
                <Link to='/donate' className='btn btn-primary btn-lg'>
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr01}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselTresMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselTresMessageDOS')}
                </p>
                <Link to='/donate' className='btn btn-primary btn-lg'>
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='prev'
        >
          <span className='carousel-control-prev-icon' aria-hidden='true' />
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='next'
        >
          <span className='carousel-control-next-icon' aria-hidden='true' />
          <span className='visually-hidden'>Next</span>
        </button>
      </div>

      <div className='about-container'>
        {/* About us */}
        <section className='d-flex row justify-content-center align-items-center col-12 col-md-12 pt-6 mb-6'>
          <img
            src={momo01}
            loading='lazy'
            alt='Imagen no disponible'
            className='img-fluid col-7 col-sm-4 col-md-5 col-lg-4 col-xl-3 col-xxl-3'
          />
          <div className='col-10 col-sm-8 col-md-10 col-lg-7 col-xl-5 col-xxl-5'>
            <h2 className='color-primary title text-center-mobile pt-5'>
              {t('quienesSomos')}
            </h2>
            <p className='fs-4 pt-3 text-center-mobile '>{t('textoSomos')}</p>
          </div>
        </section>
        {/* our values */}
        <section className='d-flex row justify-content-center align-items-start col-12 col-md-12 pt-6 pb-5 bg-primary-light'>
          <h2 className='text-center color-black title pb-5'>
            {t('nuestrosValores')}
          </h2>
          <div className='d-flex row justify-content-between align-items-center col-12 col-md-8 col-lg-10 col-xl-11'>
            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value01}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>{t('Amor')}</h2>
            </div>
            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value02}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>{t('Servicio')}</h2>
            </div>
            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value03}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>{t('Libertad')}</h2>
            </div>

            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value04}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>{t('Igualdad')}</h2>
            </div>
            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value05}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>
                {t('AprendizajeEntrePares')}
              </h2>
            </div>
            <div className='d-flex row justify-content-center col-12 col-md-4 col-lg-4 col-xl-2 mt-5'>
              <img
                loading='lazy'
                src={value06}
                alt='conocenos'
                className='col-4 col-md-10 col-lg-4 col-xl-8'
              />
              <h2 className='text-center color-black fw-4'>
                {t('EscuchaActiva')}
              </h2>
            </div>
          </div>
        </section>
        {/* our team */}
        <section className='d-flex row justify-content-center align-items-start col-12 col-md-12 pt-6'>
          <h2 className='text-center color-primary title text-center-mobile pt-5 pb-5'>
            {t('Nuestro Equipo')}
          </h2>
          <div className='d-flex row justify-content-between align-items-start col-12 col-md-10 col-lg-11 col-xl-10 pt-5'>
            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab01})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Karla Rejas</h4>
                  <span>CEO &amp; Co-Founder</span>
                </div>
                <p>{t('karlateam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/karla-rejas/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab02})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Edwin Mejía</h4>
                  <span>Co-Founder</span>
                </div>
                <p>{t('Edwinteam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/edwin-mejia-1a751a66/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*  <a
                    target='_blank'
                    href='https://www.instagram.com/stanley_3dw1n/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab03})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Avril Olarte</h4>
                  <span>{t('cartoonist')}</span>
                </div>
                <p>{t('Avrilteam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/avril-gabrielly-olarte-torres-8417ab227/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab05})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Giselle Morales</h4>
                  <span>Project Manager</span>
                </div>
                <p>{t('Giselleteam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/gisellemoralesvargas/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab06})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Valeria Reyes</h4>
                  <span>{t('leadOfWellness')}</span>
                </div>
                <p>{t('Valeriateam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/valeria-reyes-naranjo'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab07})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Esthefany Linares</h4>
                  <span>{t('leadOfInnovationTechnology')}</span>
                </div>
                <p>{t('Esthefanyteam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/esthefany-linares'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab08})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Gustavo Jimenez</h4>
                  <span>{t('Lead of Platform')}</span>
                </div>
                <p>{t('Gustavoteam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/gustavo-jimenez-jaramillo-9a17911a8/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className='team__item'
              style={{
                backgroundImage: `url(${collab09})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <div className='team__text'>
                <div className='team__title'>
                  <h4>Juan Vela</h4>
                  <span>{t('allianceLeader')}</span>
                </div>
                <p>{t('juanVelateam')}</p>
                <div className='text-center team__icons'>
                  <a
                    target='_blank'
                    href='https://www.linkedin.com/in/juan-jose-vela-linares/'
                    rel='noreferrer'
                  >
                    <AiFillLinkedin className='fs-6' />
                  </a>
                  {/*                   <a
                    target='_blank'
                    href='https://www.instagram.com/karli.nha/'
                    rel='noreferrer'
                  >
                    <AiOutlineInstagram className='fs-6' />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* who is momo? */}
        <section className='d-flex row justify-content-around align-items-center col-12 mt-6 pt-6 pb-6 bg-fifth-light'>
          <div className='d-flex row justify-content-around align-items-center col-12 col-md-12 col-lg-12 col-xl-10'>
            <div className='col-10 col-md-10 col-lg-6 col-xl-8'>
              <h2 className='color-primary title text-center-mobile'>
                {t('PersonajeMomo')}
              </h2>
              <p className='fs-4 pt-4 text-center-mobile'>
                {t('Personajetex1Momo')}
              </p>
              <p className='fs-4 text-center-mobile'>
                {t('Personajetex2Momo')}
              </p>
            </div>
            <img
              loading='lazy'
              src={momo02}
              alt='Sobre mí'
              className='img-fluid col-7 col-md-5 col-lg-4 col-xl-3 pt-5'
            />
          </div>
        </section>
        {/* what we do? */}
        <section className='d-flex row justify-content-around align-items-center col-12 col-md-12 col-lg-12 col-xl-10 pt-6 pb-6'>
          <div className='col-10 col-md-10 col-lg-6 col-xl-8 pt-5'>
            <h2 className='color-primary title text-center-mobile'>
              {t('QueHacemos')}
            </h2>
            <p className='pt-4 fs-4 text-center-mobile'>{t('Quéhacemostex')}</p>
          </div>
          <img
            loading='lazy'
            src={momo03}
            alt='Sobre mí'
            className='img-fluid col-7 col-md-5 col-lg-4 col-xl-3 pt-5'
          />
        </section>
      </div>
      {/*Allies */}
      <section className='bg-fifth-light'>
        <Aliado />
      </section>
      {/* Footer */}
      <div className='bg-primary-dark'>
        <Footer />
      </div>
    </div>
  );
}

export default About;
