import '../assets/styles/header.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo_blanco.png';
import peruFlag from '../assets/images/flags/peru.png';
import usaFlag from '../assets/images/flags/usa.png';

function Header() {
  const [color, setColor] = useState(false);
  const [open, setOpen] = useState(false);
  const [lenguageFlag, setLenguageFlag] = useState(false);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        setColor(window.pageYOffset > window.innerHeight - 500);
      });
    }

    if (lenguageFlag) {
      i18n.changeLanguage('en');
    }
  }, []);

  const openHamburger = () => {
    setOpen(!open);
  };

  const changeLenguage = () => {
    if (lenguageFlag) {
      i18n.changeLanguage('es');
    } else {
      i18n.changeLanguage('en');
    }
    setLenguageFlag(!lenguageFlag);
  };

  return (
    <div>
      <nav className={`${color ? 'header_color' : 'header'}`} id='nav'>
        <Link to='/' className='header_logo col-4 col-sm-4'>
          <img src={logo} alt='Not Found' className='col-12' />
        </Link>

        <ul className='header__nav col-7 col-sm-8 col-md-9 col-lg-7 col-xl-4'>
          <li>
            <Link to='/'>{t('inicio')}</Link>
          </li>

          <li>
            <Link to='/about'>{t('conocenos')}</Link>
          </li>

          <li>
            <Link to='/donate'>{t('comoAyudar')}</Link>
          </li>

          <li>
            <button
              type='button'
              className='btn-language rounded-circle'
              onClick={changeLenguage}
            >
              <img src={lenguageFlag ? peruFlag : usaFlag} alt='NoFound' />
            </button>
          </li>
        </ul>
        <div id='hamburger'>
          <button
            type='button'
            className={`${open ? 'open' : ''}`}
            id='hamburger_button'
            onClick={openHamburger}
          >
            <span className='top-line' />
            <span className='middle-line' />
            <span className='bottom-line' />
          </button>
        </div>
      </nav>

      <nav
        className={`${open ? 'header-mobile' : 'no-display'}`}
        id='hamburger_button'
      >
        <div className='d-flex align-content-start col-12 col-sm-12 col-md-12'>
          <img
            src={logo}
            alt='Not Found'
            className='header_logo col-4 col-sm-4'
          />

          <div id='hamburger'>
            <button
              type='button'
              className={`${open ? 'open' : ''}`}
              id='hamburger_button'
              onClick={openHamburger}
            >
              <span className='top-line' />
              <span className='middle-line' />
              <span className='bottom-line' />
            </button>
          </div>
        </div>

        <ul className='col-12 col-sm-12 col-md-12'>
          <li className='header-mobile__link'>
            <Link to='/' onClick={openHamburger}>
              {t('inicio')}
            </Link>
          </li>

          <li className='header-mobile__link'>
            <Link to='/about' onClick={openHamburger}>
              {t('quienesSomos')}
            </Link>
          </li>

          <li className='header-mobile__link'>
            <Link to='/donate' onClick={openHamburger}>
              {t('dona ahora')}
            </Link>
          </li>

          <li className='header-mobile__link'>
            <button
              type='button'
              className='btn-language btn-language--mobile rounded-circle'
              onClick={changeLenguage}
            >
              <img src={lenguageFlag ? peruFlag : usaFlag} alt='NoFound' />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
