import '../assets/styles/aliado.scss';
import aliado1 from '../assets/images/aliados/edifika.png';
import aliado2 from '../assets/images/aliados/emptor.png';
import aliado3 from '../assets/images/aliados/amazon_bamboo-min.png';
import aliado4 from '../assets/images/aliados/wempo.png';

const Aliado = () => (

  <div className='container-fluid d-flex row justify-content-center '>
    <div
      id='carouselAllyIndicators'
      className='carousel slide carouselAlly'
      data-bs-ride='carousel'
    >
      <div className='carousel-inner'>
        <div className='carousel-item active'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado3} loading='lazy' className='momo-img-03' alt='No disponible' />
            </div>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado1} loading='lazy' className='momo-img-03' alt='No disponible' />
            </div>
          </div>
        </div>
        <div className='carousel-item'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex flex-column align-items-center'>
              <img src={aliado2} loading='lazy' className='momo-img-02' alt='No disponible' />
            </div>
            <div className='col-md-5 d-flex flex-column align-items-center'>
              <img src={aliado4} loading='lazy' className='momo-img-02' alt='No disponible' />
            </div>
          </div>
        </div>
      </div>
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carouselAllyIndicators'
        data-bs-slide='prev'
      >
        <span
          className='carousel-control-prev-icon carousel-control-prev-icon--ally'
          aria-hidden='true'
        />
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carouselAllyIndicators'
        data-bs-slide='next'
      >
        <span
          className='carousel-control-next-icon carousel-control-next-icon--ally '
          aria-hidden='true'
        />
        <span className='visually-hidden'>Next</span>
      </button>
    </div>

    <div
      id='carouselAllyIndicatorsMobile'
      className='carousel slide carouselAllyMobile'
      data-bs-ride='carousel'
    >
      <div className='carousel-inner'>
        <div className='carousel-item active'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado3} loading='lazy' className='momo-img-01' alt='No disponible' />
            </div>
          </div>
        </div>

        <div className='carousel-item'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado1} loading='lazy' className='momo-img-01' alt='No disponible' />
            </div>
          </div>
        </div>
        <div className='carousel-item'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado2} loading='lazy' className='momo-img-02' alt='No disponible' />
            </div>
          </div>
        </div>

        <div className='carousel-item'>
          <div className='carousel-item--alianza'>
            <div className='col-md-5 d-flex row justify-content-center'>
              <img src={aliado4} loading='lazy' className='momo-img-02' alt='No disponible' />
            </div>
          </div>
        </div>
      </div>
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target='#carouselAllyIndicatorsMobile'
        data-bs-slide='prev'
      >
        <span
          className='carousel-control-prev-icon carousel-control-prev-icon--ally'
          aria-hidden='true'
        />
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target='#carouselAllyIndicatorsMobile'
        data-bs-slide='next'
      >
        <span
          className='carousel-control-next-icon carousel-control-next-icon--ally '
          aria-hidden='true'
        />
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  </div>
);

export default Aliado;
