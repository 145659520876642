import '../assets/styles/donate.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Aliado from '../components/aliado';
import Footer from '../components/footer';
import Header from '../components/header';
import Testimonio from '../components/testimonio';

import cr01 from '../assets/images/donacion_01.jpg';
import cr02 from '../assets/images/donacion_02.jpg';

import momo from '../assets/images/personajes_07.png';
import momo01 from '../assets/images/personajes_03.png';
/* 
import bcp from '../assets/images/bcp.png';
import yape from '../assets/images/yape.png';
import qr from '../assets/images/yape-qr.png'; */

function Donate() {
  const [t] = useTranslation('translation');

  return (
    <div className='container-fluid bg-white'>
      {/* <!-- Navigation --> */}
      <Header />
      {/* <!-- Carousel --> */}
      <div
        id='carouselIndicators'
        className='carousel slide'
        data-bs-ride='carousel'
      >
        <div className='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
          />
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          />
        </div>
        <div className='carousel-inner'>
          <div className='carousel-item carousel-item--banner active'>
            <div>
              <img
                src={cr01}
                loading='lazy'
                className='banner__img'
                alt='No disponible'
              />
            </div>
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselMessageUNO')}
                </h4>
                <p className='carousel-message__secondary line-height-carrusel'>
                  {t('carouselMessageDOS')}
                </p>
                <Link to='/donate' className='btn btn-primary btn-lg'>
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
          <div className='carousel-item carousel-item--banner'>
            <img
              src={cr02}
              loading='lazy'
              className='banner__img'
              alt='No disponible'
            />
            <div className='carousel-caption--overlay'>
              <div className='carousel-message'>
                <h4 className='carousel-message__principal'>
                  {t('carouselDosMessageUNO')}
                </h4>
                <p className='carousel-message__secondary'>
                  {t('carouselDosMessageDOS')}
                </p>
                <Link to='/donate' className='btn btn-primary btn-lg'>
                  {t('dona ahora')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button
          className='carousel-control-prev'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='prev'
        >
          <span className='carousel-control-prev-icon' aria-hidden='true' />
          <span className='visually-hidden'>Previous</span>
        </button>
        <button
          className='carousel-control-next'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='next'
        >
          <span className='carousel-control-next-icon' aria-hidden='true' />
          <span className='visually-hidden'>Next</span>
        </button>
      </div>
      {/* <!-- Donate --> 
      <section className='donate-container col-12'>
        <p className='title text-center color-primary col-12 col-md-12'>
          {t('Apóyanos con tu donación')}
        </p>
        <div className='donate-card col-12 col-sm-8 col-md-5 col-lg-4 bg-primary-light'>
          <img src={yape} className='logo-img' alt='No disponible' />
          <img
            src={qr}
            className='col-12 col-sm-10 col-md-10'
            alt='No disponible'
          />
        </div>
        <div className='donate-card col-12 col-sm-8 col-md-5 col-lg-4 bg-primary-light'>
          <img src={bcp} className='logo-img' alt='No disponible' />
          <div className='donate-card__description col-12 col-md-10'>
            <p>{t('donarSoles')}</p>
            <p>Banco de Crédito del Perú BCP:</p>
            <p>{t('cuenta')} : 19491189210011</p>
            <p>CCI: 00219419118921001193</p>
            <p>{t('donarDolares')}</p>
            <p>Banco de Crédito del Perú BCP:</p>
            <p>{t('cuenta')}: 19491189356158</p>
            <p>CCI: 00219419118935615896</p>
          </div>
        </div>
      </section>*/}
      {/* <!-- Donate Talent --> */}
      <section className='col-12 row col-md-12 donate-container'>
        <img
          src={momo}
          className='col-12 col-md-5 donate-img-alt'
          alt='No disponible'
        />
        <div className='col-12 col-sm-9 col-md-10 col-lg-7'>
          <p className='donate-title text-blod color-primary'>
            {t('Dona tu talento')}
          </p>
          <div className='donate-description'>
            <p>{t('DonaTEXtalento')}</p>

            <a
              href='mailto:talento@momoteescucha.org'
              target='_blank'
              className='donate-button text-blod'
              rel='noreferrer'
            >
              {t('Escribenos Aqui')}
            </a>
          </div>
        </div>

        <img
          src={momo}
          className='col-12 col-md-4 donate-img'
          alt='No disponible'
        />
      </section>

      <section className='col-12 col-md-12 donate-container bg-fifth-light'>
        <img
          src={momo01}
          className='col-12 col-md-5 donate-img-alt'
          alt='No disponible'
        />

        <div className='col-12 col-sm-9 col-md-10 col-lg-7'>
          <p className='donate-title text-blod color-primary'>
            {t('Auspicia un proyecto')}
          </p>
          <div className='donate-description'>
            <p>{t('Auspiciatexproyecto')}</p>
            <a
              href='https://forms.gle/hugryy6mJPbiB1Xo8'
              target='_blank'
              className='donate-button text-blod'
              rel='noreferrer'
            >
              {t('formulario')}
            </a>
          </div>
        </div>

        <img
          src={momo01}
          loading='lazy'
          className='col-12 col-md-4 donate-img'
          alt='No disponible'
        />
      </section>
      {/* Testimonios */}
      <section className='bg-white pt-6 mb-6'>
        <Testimonio />
      </section>
      {/* Aliados */}
      <section className='bg-fifth-light'>
        <Aliado />
      </section>
      {/* footer */}
      <div className='bg-primary-dark'>
        <Footer />
      </div>
    </div>
  );
}

export default Donate;
