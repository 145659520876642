import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ErrorNotFound from '../components/errorNotFound';
import About from '../containers/about';
import Donate from '../containers/donate';
import Home from '../containers/home';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/donate' component={Donate} />
      <Route exact path='/about' component={About} />
      <Route component={ErrorNotFound} />
    </Switch>
  </BrowserRouter>
);

export default App;
