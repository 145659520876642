import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../i18n/en/translation.json';
import translationES from '../i18n/es/translation.json';

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'es',
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  react: {
    transSupportBasicHtmlNodes: true,
  },
});

export default i18n;
